.wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 2.5rem;
}

.content {
	width: calc(var(--default-body-max-width) - var(--default-sidebar-desktop-width));
	margin: 2rem 2rem 0;
}

.tableContent {
	width: 100%;
	border-spacing: 0 0.2rem;
}

.contentHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 6.4rem;
	border-bottom: 1px solid var(--border-content-header-color);
	padding-bottom: 2rem;
}

.contentHeader > h2 {
	color: var(--black-color);
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.searchAndFilter {
	width: calc(var(--filter-width) + var(--search-width));
	height: var(--tab-height);
	display: flex;
	justify-content: right;
	align-items: center;
}

.searchAndFilter select {
	width: var(--filter-width);
	height: 100%;
	margin-right: 0.4rem;
	color: black;
	border-radius: var(--row-border-radius);
	padding: 0.8rem;
}

.search {
	display: flex;
	align-items: center;
	width: var(--search-width);
	height: 100%;
	padding: 0.4rem 0 0.4rem 0.4rem;
	border-radius: var(--row-border-radius);
	background: #f7f6f6;
}

.search input {
	height: 100%;
	flex: 1;
	color: black;
	padding-left: 0.8rem;
	border: none;
}

.search input::placeholder {
	color: black;
	font-weight: 500;
}

.iconSearch {
	width: 4.4rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.searchIcon {
	cursor: pointer;
	color: black;
	border-left: 1px solid var(--border-color);
	padding-left: 0.8rem;
	font-size: 2.8rem;
	margin-right: 0.8rem;
}

.tabs {
	width: 100%;
	height: var(--tab-height);
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0;
}

.tab {
	min-width: var(--tab-width);
	width: 24%;
	height: 100%;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4rem;
	border: 1px solid black;
	position: relative;
}

.tab:hover {
	cursor: pointer;
	background: gray;
	border-radius: 0.5rem;
}

.tab p {
	color: black;
	text-align: center;
	margin: 0 1rem;
}

.tab.active {
	background: black;
	cursor: default;
	border-radius: 0.5rem;
}

.tab.active p {
	color: var(--white-color);
}

.tab.noActive {
	z-index: 2;
}

.contentbody {
	margin-top: 1rem;
	flex: 1;
	overflow-x: auto;
	padding-bottom: 0.2rem;
	width: 100%;
}

.contentbody::-webkit-scrollbar {
	height: 1.4rem;
	background: var(--background-color);
}

.contentbody::-webkit-scrollbar-thumb {
	height: 0.8rem;
	background: var(--background-button-next-hover-color);
	border-radius: 1rem;
}

.contentbody > table > thead > tr {
	height: 5rem;
}

.contentbody > table > thead > tr > th:nth-child(1) {
	border-top-left-radius: 0.6rem;
	border-bottom-left-radius: 0.6rem;
}
.delete {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
}
.contentbody > table > thead > tr > th {
	font-size: var(--default-font-size);
	padding: 0 0.8rem;
	color: #fff;
	font-weight: 600;
	background: black;
}

.contentbody > table > tbody > tr {
	background: var(--tr-tag-background-color);
	height: 5rem;
	border-left: 0.4rem solid transparent;
}

.contentbody > table > tbody > tr:hover {
	box-shadow: 0.4rem 0.8rem 2.2rem var(--shadow-color);
	background: var(--white-color);
}

.pointer {
	cursor: pointer;
}

.contentbody > table > tbody > tr td:first-child {
	border-top-left-radius: var(--row-border-radius);
	border-bottom-left-radius: var(--row-border-radius);
}

.contentbody > table > tbody > tr:hover td:first-child {
	border-left: 0.4rem solid black;
}

.contentbody > table > tbody > tr td:last-child {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
}

.contentbody > table > tbody > tr > td {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20rem;
	padding: 0 0.8rem;
}

.contentbody > table > thead > tr > th.debtorCompanyName,
.contentbody > table > tbody > tr > td.debtorCompanyName,
.contentbody > table > thead > tr > th.expiresIn,
.contentbody > table > tbody > tr > td.expiresIn {
	text-align: left;
}

.contentbody > table > thead > tr > th.acn,
.contentbody > table > tbody > tr > td.acn,
.contentbody > table > thead > tr > th.demandAmount,
.contentbody > table > tbody > tr > td.demandAmount {
	text-align: right;
}

.contentbody > table > thead > tr > th.link,
.contentbody > table > tbody > tr > td.link {
	text-align: center;
}

.demandId {
	width: 12rem;
	min-width: 10rem;
}

.debtorCompanyName {
	min-width: 20rem;
	width: 20rem;
}

.acn {
	width: 12rem;
	min-width: 12rem;
}

.link {
	width: 15rem;
	min-width: 14rem;
	text-decoration: underline;
	text-decoration-color: rgb(90, 90, 255);
	color: rgb(125, 125, 255);
}

.demandAmount {
	width: 15rem;
	min-width: 14rem;
}

.dateIssued {
	width: 15rem;
	min-width: 12rem;
}

.expiresIn {
	min-width: 9rem;
	width: 10rem;
}

.expiresIn.orange {
	color: var(--orange-text);
}

.expiresIn.red {
	color: var(--error-input-color);
}

.expiresIn.green {
	color: var(--success-color);
}

.delete {
	width: 9rem;
	white-space: pre;
	padding-right: 1rem;
}

.deleteIcon {
	font-size: 2rem;
	color: var(--warning-dangerous-color);
}

.deleteIcon:hover {
	color: var(--error-border-color);
}

.pagination {
	margin-top: 1.6rem;
}

.approve {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	min-width: 12rem;
	max-width: 6rem;
	width: 10rem;
	white-space: pre;
}

.buttonPost {
	background: var(--main-color);
	color: var(--white-color);
	border: none;
	border-radius: var(--row-border-radius);
	padding: 0.6rem 0.8rem;
	font-size: var(--default-font-size);
	font-weight: 600;
	cursor: pointer;
	opacity: 0.72;
	text-align: center;
	font-size: 1.3rem;
}

.buttonPost:hover {
	opacity: 1;
}

.action {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	min-width: 4rem;
	max-width: 6rem;
	width: 5rem;
	white-space: pre;
	padding-right: 1rem;
}

@media (max-width: 1190px) {
	.content {
		width: 100%;
		padding: 0 2rem;
	}

	.tabs {
		height: auto;
		gap: 1rem;
	}

	.tab {
		width: 48%;
		height: var(--tab-height);
	}
}

@media (max-width: 1024px) {
	.content {
		padding: 0 0.4rem;
	}

	.contentHeader {
		margin-bottom: 2rem;
		height: auto;
	}

	.contentHeader > h2 {
		font-size: 2.4rem;
	}
}

@media (max-width: 714px) {
	.contentHeader > h2 {
		margin-bottom: 1rem;
	}

	.contentHeader {
		flex-direction: column;
	}
}

@media (max-width: 510px) {
	.searchAndFilter {
		width: 100%;
		margin: 0 0.4rem;
	}

	.search {
		width: calc(100% - (var(--filter-width)));
	}

	.search input {
		min-width: 1rem;
	}
}

@media (max-width: 429px) {
	.contentHeader {
		flex-direction: column;
		height: auto;
	}

	.contentHeader > h2 {
		text-align: center;
		margin-bottom: 1rem;
	}
}
