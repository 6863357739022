.banner {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 .8rem;
	width: 100%;
	height: var(--tab-height);
	background: var(--banner-color);
	margin-bottom: 2rem;
	position: relative;
}

.banner::before,
.banner::after {
	content: '';
	position: absolute;
	border: calc((var(--tab-height))/2) solid;
	cursor: default;
}

.banner::before {
	border-color: transparent transparent transparent white;
	left: 0;
}

.banner::after {
	border-color: transparent white transparent transparent ;
	right: 0;
}

.banner p {
	width: 100%;
	color: var(--white-color);
	text-align: center;
}