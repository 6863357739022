.wrapper {
	--width-input-type-number: 5rem;
	height: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 3rem;
}

.content {
	width: calc(var(--default-body-max-width) - var(--default-sidebar-desktop-width));
	margin: 2rem 2rem 0;
}

.row {
	width: 100%;
	height: var(--tab-height);
	display: flex;
	justify-content: left;
	align-items: center;
}

.text {
	min-width: var(--text-row-width);
	width: var(--text-row-width);
	display: flex;
	justify-content: right;
	margin-right: 1rem;
	line-height: 2rem;
	word-wrap: break-word;
}

.setting {
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
}

.iconBackUpDB {
	font-size: calc(var(--tab-height) * var(--two-thirds));
}

.iconBackUpDB:hover {
	cursor: pointer;
	opacity: var(--icon-opacity-default);
}

.setting > input[type='number'] {
	height: calc(var(--tab-height) * var(--two-thirds));
	max-width: var(--width-input-type-number);
	min-width: var(--width-input-type-number);
	outline: none;
	border: 1px solid var(--text-color);
}

.background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--black-color);
	opacity: 0.4;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
