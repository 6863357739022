.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--modal-background-color);
	z-index: 99999999;
}

.icon-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	font-size: 2.5rem;
	color: black;
	cursor: pointer;
}

.icon-edit {
	position: absolute;
	top: 1rem;
	right: 7rem;
	font-size: 2.5rem;
	color: black;
	cursor: pointer;
}

.download-btn {
	display: flex;
	gap: 0.5rem;
	font-size: 1.5rem;
	cursor: pointer;
	color: black;
	padding: 0.4rem;
	border: 1px solid var(--border-color);
	border-radius: 0.4rem;
	transition: 0.2s;

	&:hover {
		color: var(--white-color);
		background-color: gray;
	}
}

.line-contact {
	position: relative;
	width: 100%;
	border-top: 1px solid var(--border-color);
	margin: 3rem 0;
}

.body-modal {
	position: relative;
	width: 50%;
	max-width: 80rem;
	padding: 1.6rem 3.5rem;
	background-color: var(--white-color);
	border-radius: 1rem;
	overflow-y: auto;
	max-height: 70rem;

	& form {
		margin-top: 2.4rem;
	}
}

.container_btn {
	display: flex;
	justify-content: center;
}

.button-save {
	text-align: center;
	margin-left: 2rem;
	color:white;
	background-color: black;
	border: none;
	outline: none;
	padding: 1.3rem 0;
	cursor: pointer;
	border-radius: 0.8rem;
	font-weight: 600;
	font-size: 1.4rem;
	opacity: 0.65;
	margin-top: 1rem;
	width: 200px !important;
	&:hover {
		opacity: 1;
	}
}

.button-save.draft {
	background-color: transparent;
	color: var(--text-color);
	border: 1px solid var(--text-color);
}

.body-modal::-webkit-scrollbar {
	background-color: var(--background-color);
	width: 0.5rem;
	border-radius: 1.8rem;
}

.body-modal::-webkit-scrollbar-thumb {
	border-radius: 1.8rem;
	background: var(--scroll-top-to-bottom-color);
}

.body-modal::-webkit-scrollbar {
	width: 1rem;
	background-color: transparent;
}

.body-modal::-webkit-scrollbar-thumb {
	width: 1rem;
}

.input_stat_demand_documents_2 {
	cursor: pointer;
}

.select-input {
	color: black;
	cursor: pointer;
	outline: none;
	border: none;
	height: 100%;
	padding: 1rem 0;
}

.selector {
	border: 1px solid var(--border-color);
	border-radius: 0.6rem;
	padding: 0 0.6rem
}

.text-field {
	display: flex;
	align-items: center;
	gap: 2rem;
	white-space: wrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0.4rem 0;
	& h4 {
		width: 30%;
		color: var(--title-demand-detailed-color);
		text-align: left;
	}

	&:not(:last-child) {
		margin-bottom: 0.5rem !important;
	}

	& .input {
		padding: 0.4rem 0.6rem;
	}

	& p {
		word-wrap: break-word;
		// display: block;
		// display: -webkit-box;
		// max-width: 100%;
		// margin: 0 auto;
		// font-size: 14px;
		// line-height: 1;
		// -webkit-line-clamp: 3;
		// -webkit-box-orient: vertical;
		// overflow: hidden;
		// text-overflow: ellipsis;
		color: black;
		flex: 1;
		margin-left: 0;
	}

	& .comments {
		line-height: 1.4;
		width: calc(100% - 22.5rem);
		inline-size: 10px;
		overflow-wrap: break-word;
	}

	&.download {
		align-items: center;
	}
}
.comments_input {
	text-align: left;
	flex: 1;
	height: 15rem;
	font-size: 1.5rem;
	line-height: 1.5;
	padding: 0.8rem;
}

.important {
	color: var(--error-border-color);
}

.wrap_input {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
}

.button-ban {
	color: var(--white-color);
	background-color: var(--error-border-color);
	border: none;
	outline: none;
	padding: 1.3rem 0;
	cursor: pointer;
	border-radius: 0.8rem;
	font-weight: 600;
	font-size: 1.4rem;
	opacity: 0.92;
	margin-top: 1rem;
	width: 40%;
	transform: translate(50%, 0);

	&:hover {
		opacity: 1;
	}
}

.contact-details {
	position: absolute;
	top: -10px;
	left: 42.5%;
	right: 42.5%;
	background-color: var(--white-color);
	padding: 0 1rem;
	text-align: center;
	color: var(--title-demand-detailed-color);
}

.error-message {
	color: var(--error-border-color);
}

.message {
	color: var(--error-border-color);
	width: 25rem;
	font-size: 1.3rem;
	margin-top: 0.4rem;
}

.comments {
	width: 800px;
}

@media (max-width: 1200px) {
	.body-modal {
		width: 90%;
	}
}

@media (max-width: 1049px) {
	.contact-details {
		left: 35.5%;
		right: 35.5%;
	}
}

@media (max-width: 1106px) {
	.contact-details {
		left: 30.5%;
		right: 30.5%;
	}
}

@media (max-width: 992px) {
	.text-field.download.medium {
		height: 120px !important;
	}

	.text-field.medium {
		height: 80px;
	}

	.wrap_icon_download {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	.container_btn {
		flex-direction: column;
		align-items: center;
	}
	.button-save {
		width: 100% !important;
		margin-left: 0;
	}
	.text-field {
		display: flex;
		align-items: center;
		gap: 0.6rem;
		&.medium {
			height: 4.5rem;
		}
		&:not(:last-child) {
			margin-bottom: 2.5rem;
		}

		& h4 {
			width: 30%;
			color: var(--title-demand-detailed-color);
			text-align: left;
		}

		& p {
			padding: 0;
			margin: 0;
			color: var(--text-color);
			flex: 1;
			margin-left: 0;
		}

		& .comments {
			line-height: 1.4;
			width: calc(100% - 22.5rem);
			inline-size: 10px;
			overflow-wrap: break-word;
		}

		&.download {
			align-items: center;
		}
	}
}

@media (max-width: 834px) {
	.contact-details {
		left: 33.5%;
		right: 33.5%;
	}
}

@media (max-width: 521px) {
	.contact-details {
		left: 18.5%;
		right: 18.5%;
	}
}

@media (max-width: 768px) {
	.body-modal {
		overflow-y: auto;
	}

	.text-field {
		& h4 {
			width: 17rem;
			font-size: 1.4rem;
		}

		& p {
			color: var(--text-color);
			flex: 1;
			font-size: 1.4rem;
		}

		& .comments {
			width: calc(100% - 17rem);
			inline-size: 10px;
			overflow-wrap: break-word;
		}
	}
}

@media (max-width: 550px) {
	.body-modal {
		overflow-y: auto;
	}

	.text-field {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		& h4 {
			width: 17rem;
			font-size: 1.4rem;
		}

		& p {
			color: var(--text-color);
			flex: 1;
			font-size: 1.4rem;
		}

		& .comments {
			width: 100%;
		}

		&.download {
			align-items: flex-start;
		}

		&.comments_input {
			width: 2rem;
			display: flex;
			flex: 1;
		}
	}
}
