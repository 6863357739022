.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-position: center;
	overflow: hidden;
}

.background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
}

.background-video video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
}

.layer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.content {
	width: 96%;
}
