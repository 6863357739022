.app {
	background: #fff;
	overflow: auto;
	overflow: hidden;
}

.body {
	margin-top: var(--default-header-desktop-height);
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	min-height: 100vh;
}



.footer-area {
	display: flex;
	justify-content: center;
}
