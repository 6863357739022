.wrapper {
	width: var(--medium-body-width);
	padding: 1rem 1.6rem 3rem;
	margin: 0 auto;
}

.settings-title {
	font-size: 3rem;
	margin-bottom: 3rem;
}

.settings-group {
	margin-top: 2rem;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.settings-body {
	background-color: var(--white-color);
	padding: 1.6rem;
	border-radius: 0.8rem;
	margin-top: 1.4rem;

	&:not(:first-child) {
		margin-top: 3rem;
	}

	& .title {
		font-size: 2.4rem;
		margin-bottom: 2rem;
		margin-left: 0;
	}

	& .form-group {
		display: flex;
		flex-direction: column;
		gap: 1.8rem;
	}

	& .form-field {
		display: flex;
		flex-direction: column;
	}

	& .error-text {
		color: var(--error-border-color);
		margin-top: 0.4rem;
		font-size: 1.1rem;
	}

	& .label-field {
		margin-bottom: 0.8rem;
		font-size: 1.5rem;
		font-weight: 600;
		color: black;
	}

	& .input-field {
		color: black;
		outline-color: var(--text-color);
		padding: 1.2rem 0.8rem;
		border: 1px solid var(--border-color);
		border-radius: 0.4rem;
		appearance: none;
		margin: 0;
		width: 100%;
		&::placeholder {
			color: var(--placeholder-color);
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	& .button-save {
		color: white;
		background-color: black;
		border: none;
		outline: none;
		padding: 1.3rem 0;
		cursor: pointer;
		border-radius: 0.8rem;
		font-weight: 600;
		font-size: 1.4rem;
		opacity: 0.92;
		margin-top: 1rem;
		
		&:hover {
			opacity: 1;
		}
	}
}

.input-field.read-only {
	background-color: #f3f3f3;
	pointer-events: none;
}

.password-group {
	position: relative;
	& .input-field {
		width: 100%;
	}
	& .eyes-btn {
		position: absolute;
		right: 10px;
		top: 15px;
		color: var(--text-color);
		cursor: pointer;
	}
}
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(255, 255, 255);
	transition: 0.4s;
	border: 1px solid black;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 3px;
	bottom: 4px;
	top: 2px;
	background-color: rgb(0, 0, 0);
	transition: 0.4s;
	border-radius: 50%;
	border: 0.5px solid black;
}

input:checked+.slider {
	background-color: black;
}

input:checked+.slider:before {
	transform: translateX(26px);
	background-color: rgb(255, 255, 255);
}
.important {
	color: var(--error-border-color);
}
.user-name {
	color: var(--text-color);
}

@media (max-width: 1024px) {
	.wrapper {
		max-width: 100%;
		width: 100%;
		padding: 1rem 0.8rem 3rem;
	}

	.settings-group {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
}
