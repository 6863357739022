.wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 3rem;
}

.content {
	width: calc(var(--default-body-max-width) - var(--default-sidebar-desktop-width));
	margin: 2rem 2rem 0;
}

.tableContent {
	width: 100%;
	border-spacing: 0 0.2rem;
}

.contentHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 6.4rem;
	border-bottom: 1px solid var(--border-content-header-color);
	padding-bottom: 2rem;
}

.contentHeader > h2 {
	color: var(--black-color);
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 1rem 0;
}

.contentbody {
	margin-top: 2rem;
	flex: 1;
	overflow-x: auto;
	padding-bottom: 0.2rem;
	width: 100%;
}

.contentbody::-webkit-scrollbar {
	height: 1.4rem;
	background: var(--background-color);
}

.contentbody::-webkit-scrollbar-thumb {
	height: 0.8rem;
	background: var(--background-button-next-hover-color);
	border-radius: 1rem;
}

.contentbody > table > thead > tr {
	height: 5rem;
}

.contentbody > table > thead > tr > th:nth-child(1) {
	border-top-left-radius: 0.6rem;
	border-bottom-left-radius: 0.6rem;
}

.contentbody > table > thead > tr > th {
	font-size: var(--default-font-size);
	padding: 0 0.4rem;
	color: white;
	font-weight: 600;
	background: black;
}

.contentbody > table > tbody > tr {
	background: var(--tr-tag-background-color);
	height: 5rem;
	border-left: 0.4rem solid transparent;
}

.contentbody > table > tbody > tr:hover {
	cursor: pointer;
	box-shadow: 0.4rem 0.8rem 2.2rem var(--shadow-color);
	background: var(--white-color);
}

.contentbody > table > tbody > tr:hover td.demandId {
	border-left: 0.4rem solid black;
}

.contentbody > table > tbody > tr > td {
	color: black;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 20rem;
}

.demandId {
	border-top-left-radius: var(--row-border-radius);
	border-bottom-left-radius: var(--row-border-radius);
	max-width: 14rem;
	width: 12rem;
	min-width: 9rem;
}

.debtorCompanyName {
	flex: 1;
}

.acn {
	width: 16rem;
	max-width: 20rem;
	min-width: 14rem;
}

.demandAmount {
	max-width: 40rem;
	width: 14rem;
	min-width: 14rem;
}

.dateIssued,
.expiresIn {
	width: 14rem;
	max-width: 20rem;
	min-width: 12rem;
}

.status {
	min-width: 4rem;
	max-width: 6rem;
	width: 5rem;
	white-space: pre;
	padding-right: 2rem;
}

.action {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	min-width: 4rem;
	max-width: 6rem;
	width: 5rem;
	white-space: pre;
	padding-right: 1rem;
}

.buttonPost {
	background: var(--main-color);
	color: var(--white-color);
	border: none;
	border-radius: var(--row-border-radius);
	padding: 0.4rem 0.8rem;
	font-size: var(--default-font-size);
	font-weight: 600;
	cursor: pointer;
}

.buttonPost:hover {
	background: var(--background-color);
}

.delete {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	width: 9rem;
	white-space: pre;
}

.approve {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	min-width: 12rem;
	max-width: 6rem;
	width: 10rem;
	white-space: pre;
	padding-right: 1rem;
}

.pagination {
	margin-top: 1.6rem;
}

.search {
	display: flex;
	align-items: center;
	width: 30rem;
	height: 4.4rem;
	padding: 0.4rem 0 0.4rem 0.4rem;
	border-radius: var(--row-border-radius);
	background: #f7f6f6;
}

.search input {
	height: 100%;
	flex: 1;
	color: black;
	padding-left: 0.8rem;
	border: none;
}

.search input::placeholder {
	color: black;
	font-weight: 500;
}

.iconSearch {
	width: 4.4rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.searchIcon {
	cursor: pointer;
	color: black;
	border-left: 1px solid var(--border-color);
	padding-left: 0.8rem;
	font-size: 2.8rem;
	margin-right: 0.8rem;
}
.expiresIn,
.status,
.debtorCompanyName {
	padding: 0 1rem !important;
	text-align: left !important;
}
.demandAmount,
.acn,
.demandId {
	text-align: right !important;
	padding: 0 1rem !important;
}

.remove {
	border-top-right-radius: var(--row-border-radius);
	border-bottom-right-radius: var(--row-border-radius);
	min-width: 12rem;
	max-width: 6rem;
	width: 10rem;
	white-space: pre;
	padding-right: 1rem;
}

.deleteIcon {
	font-size: 2rem;
	color: var(--warning-dangerous-color);
}

.deleteIcon:hover {
	color: var(--error-border-color);
}

.textBox {
	width: 100%;
	border: 1px solid var(--black-color);
	margin-top: 2rem;
	box-shadow: 0.4rem 0.8rem 2.2rem var(--shadow-color);
}

.textBox p {
	width: 100%;
	margin: 1rem;
	line-height: var(--textBox-line-height);
}

@media (max-width: 1180px) {
	.content {
		width: 100%;
		padding: 0 2rem;
	}
}

@media (max-width: 768px) {
	.search {
		height: 3.6rem;
	}
}

@media (max-width: 575px) {
	.search {
		width: 20rem;
	}

	.search input {
		width: 16rem;
	}
}

@media (max-width: 429px) {
	.search {
		width: 100%;
	}
}

@media (max-width: 1024px) {
	.content {
		width: 100%;
		padding: 0 0.4rem;
	}

	.contentHeader {
		margin-bottom: 2rem;
		height: auto;
	}

	.contentHeader > h2 {
		font-size: 2.4rem;
	}
}

@media (max-width: 429px) {
	.contentHeader {
		flex-direction: column;
		height: auto;
	}

	.contentHeader > h2 {
		text-align: center;
		margin-bottom: 1rem;
	}
}
