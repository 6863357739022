.sign_in {
	display: flex;
	justify-content: center;
	height: 100%;
	align-items: center;
}

.signin_classic {
	justify-content: center;
	width: 65rem;
	border-radius: 2rem;
	overflow-wrap: break-word;
	overflow-wrap: anywhere;
	padding: 4rem;
}

.logintration_content {
	margin-bottom: 1.2rem;
	text-align: center;
}

.content_login {
	padding: 1rem;
	font-size: 3rem;
	font-weight: 900;
	color: var(--text-color);
}

.title_login {
	margin-top: 1rem;
	padding: 1rem;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	margin-left: 0;
	width: 100%;
	color: var(--background-login-button-color);
}

.logintration__actions-providers {
	margin-bottom: 1rem;
	display: grid;
	grid-gap: 0.5rem;
	width: 100%;
}

.moveToSignUp {
	margin-top: 3rem;
	text-align: center;
	cursor: default;
}

.toSignUp {
	color: var(--text-color);
	cursor: default;
}

.toSignUp span:hover {
	cursor: pointer;
	text-decoration: underline;
}

.suggestion-password {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-left: 2rem;
}

.item-suggestion-password {
	margin: 0 0.5rem;
	color: var(--text-color);
}

.text-suggestion {
	color: var(--background-login-button-color);
}

.item-suggestion {
	margin: 0 0.5rem;
}

@media only screen and (max-width: 1024px) {
	.content_login {
		padding: 1rem;
		font-size: 3rem;
		font-weight: 900;
	}

	.title_login {
		padding: 1rem;
		font-size: 2rem;
		margin-top: 1.2rem;
	}

	.suggestion-password {
		margin-left: 0;
	}

	.item-suggestion-password {
		margin: 0 0.5rem;
	}

	.text-suggestion {
		color: var(--background-login-button-color);
	}

	.item-suggestion {
		margin: 0 0.5rem;
	}

	.moveToSignUp {
		margin-top: 3rem;
		text-align: center;
		cursor: default;
	}


}

@media (max-width: 475px) {
	.signin_classic {
		padding: 4rem 1rem;
	}

	.content_login {
		padding: 1rem;
		font-size: 2.8rem;
		font-weight: 900;
	}

	.title_login {
		padding: 1rem;
		font-size: 1.4rem;
		margin-top: 0.8rem;
	}

	.suggestion-password {
	display: flex;
	justify-content: center;
	flex-direction: column;
	}

	.item-suggestion-password {
		margin: 0 0.5rem;
	}

	.text-suggestion {
		color: var(--background-login-button-color);
	}

	.item-suggestion {
		margin: 0 0.5rem;
	}

	.moveToSignUp {
		margin-top: 3rem;
		text-align: center;
		cursor: default;
	}
}