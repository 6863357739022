.wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem;
	margin-top: 3rem;
	border-radius: 1rem;
	border: 1px solid var(--text-login-color);
}

.button_basic {
	transition-duration: 0.4s;
	padding: 1.5rem;
	color: var(--text-login-color);
	font-weight: 700;
	overflow: hidden;
	cursor: pointer;
	font-size: 1.6rem;
	flex: 1;
	border-radius: 1rem;
	opacity: .8;
}

.button_basic:hover {
	opacity: 1;
	color: var(--white-color);
}

.button_basic.loginButton {
	background: var(--text-color);
}