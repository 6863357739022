.wrapper {
	display: grid;
	place-items: center;
	height: 100vh;
	padding: 0 2rem;
	width: 100%;
}

.message__title {
	margin-bottom: 2rem;
	line-height: 1.4;
	& span {
		color: #969696;
	}
}

.err-number {
	text-align: center;
	margin-bottom: 1rem;
	font-size: 9rem;
    height: 100%;
	font-weight: 600;
    padding: 5rem 0;
    background: linear-gradient(to right, #9e9e9e 35%, #5e00d8 100%); 
    background-clip: text;
    color: transparent;
}

.e-nav__link {
    font-size: 1.5rem;
    text-decoration: underline;
    color: var(--text-color);
}