.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	padding: 0 0.4rem;
	background: var(--modal-confirm-background-color);
	cursor: default;
}

.boxConfirm {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 25px 0 20px;
	background-color: var(--white-color);
	border-radius: 8px;
	align-self: center;
	display: flex;
	flex-direction: column;
}

.boxHeader {
	flex: 1;
	border-top: 1px solid var(--border-color);
	text-align: center;
	padding: 30px 0 0;
	width: 100%;
	color: var(--text-color);
	font-weight: 600;
	align-self: center;
	text-align: center;
	margin: 0;
}

.groupBtn {
	display: flex;
	justify-content: center;
	padding: 0 20px;
	margin-top: 30px;
}

.btnDelete {
	color: var(--white-color);
	background: var(--background-button-submit-color);
	margin-left: 2rem;
}

.btnDelete:hover {
	background: var(--background-button-submit-hover-color);
}

.btnCancel {
	color: var(--cancel-button-text-color);
	background-color: transparent;
}

.btnCancel:hover {
	background: var(--cancel-button-background-hover-color);
	color: var(--cancel-button-text-hover-color);
	opacity: 0.8;
}

.iconAlert {
	width: 8rem;
}
