.wrapper {
	font-size: 20px;
	padding: 30px;
	text-align: justify;

	div {
		line-height: 22px;
		margin-bottom: 10px;
	}

	ul {
		margin-left: 50px;
		margin-bottom: 20px;

		li {
			line-height: 22px;

			ul {
				margin-top: 6px;
			}
		}
	}
}

.title {
	margin-bottom: 20px;
}

.content {
	padding: 5px 0 5px 0;
}

.link {
	text-decoration: underline;
	padding-bottom: 5px;
	font-weight: 600;
}
