.layout {
	display: flex;
	justify-content: center;
}

.container {
	display: flex;
	justify-content: center;
	margin: 2rem;
	flex-direction: column;
	align-items: center;
}

.wrapper {
	background-color: var(--white-color);
	border-radius: 1rem;
	display: flex;
	padding: 1.6rem;
	margin: 2rem;
	position: relative;
}

.container_form_title {
	width: 80rem;
	padding: 1rem 0;
}

.title {
	color: var(--black-color);
	font-size: 3rem;
	padding: 1rem 2rem;
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.container_button {
	margin-top: 2rem;
	flex-direction: row;
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 2rem;
}

.button_save {
	width: 30rem;
}

.button_post {
	width: 30rem;
}

.text-field {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;
}

.input-field {
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
}

p {
	width: 24rem;
	font-size: 1.5rem;
	color: black;
	margin: 1rem;
}

input {
	color: black;
	padding: 0.9rem 0.6rem;
	background: transparent;
	line-height: 1.5;
	font-size: 1.5rem;
	border-width: 1rem;
	border-radius: 0.5rem;
	flex: 1;
	display: flex;
	align-items: center;
	border: 0.1rem solid var(--border-color);
}

.comments {
	width: 100%;
	height: 20rem;
	border-radius: 0.5rem;
	border: 0.1rem solid var(--border-color);
	background: transparent;
}

input::file-selector-button {
	font-weight: bold;
	color: var(--text-color);
	padding: 0.5em;
	border: none;
	border-radius: 0.5rem;
	cursor: pointer;
}

input[type="file"] {
	border: none;
}

input[type="date"] {
	background: transparent;
	color: gray;
	font-size: 1.8rem;
	border-radius: 5px;
}

textarea {
	resize: none;
	border: 0.1rem solid var(--border-color);
	font-size: 2rem;
	font-weight: 400;
	color: black;
	outline: none;
	width: 30rem;
}

textarea:hover {
	border: 0.1rem solid black;
}

.button-save {
	color: white;
	background-color: black;
	border: none;
	outline: none;
	padding: 1.3rem 0;
	cursor: pointer;
	border-radius: 0.8rem;
	font-weight: 600;
	font-size: 1.4rem;
	opacity: 0.92;
	margin-top: 1rem;
	width: 100%;

	&:hover {
		opacity: 1;
	}
}

.button-save.draft {
	background-color: transparent;
	color: black;
	border: 1px solid black;
}

::-webkit-calendar-picker-indicator {
	background-color: gray;
	padding: 5px;
	cursor: pointer;
	border-radius: 3px;
}

.container_form_title .text-field.download p,
.container_form_title .text-field p {
	width: 190px;
}

.text-field.download {
	display: flex;
	cursor: pointer;
}

.file-upload-wrapper {
	flex: 1;
}

@media (max-width: 1024px) {
	.layout {
		width: 100%;
		margin: 0;
	}

	.title {
		padding: 1rem 0 1rem;
	}

	.container {
		width: 100%;
		margin: 0;
		padding: 2rem 0.8rem;
	}

	.wrapper {
		width: 100%;
	}

	.container_form_title {
		width: 100%;
		margin: 0;
	}
}

.important {
	color: var(--error-border-color);
}

.post-lable {
	margin-top: 2rem;
}

.message {
	margin-top: 0.4rem;
	font-size: 1.3rem;
	color: var(--error-border-color);
	width: 100%;
}

.error-message {
	width: 100%;
	color: var(--error-border-color);
}
.birthday {
	font-weight: bold;
	font-size: 1.5rem;
	display: flex;
	flex-direction: row;
	width:22rem;
	justify-content: center;
	align-items: center;
  }
  

.input_stat_demand_documents_2 {
	cursor: pointer;
}

//  cursor pointer for input type file
input[type="file"] {
	cursor: pointer;
}

.password-group {
	position: relative;
	& .input {
		width: 100%;
	}
	& .eyes-btn {
		position: absolute;
		right: 10px;
		top: 15px;
		color: black;
		cursor: pointer;
	}
}

@media (max-width: 768px) {
	.text-field.download {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	.container_button {
		width: 100%;
		flex-direction: column-reverse;
		gap: 0;
	}

	.button-save {
		flex: 1;
		width: 100%;
	}
}
@media (max-width: 475px) {
	.text-field {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}
